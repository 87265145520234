<template>
  <div class="container">
    <router-view />
  </div>
</template>
<script setup></script>

<style lang="scss">
  .back-icon {
    width: 24px;
    height: 24px;
  }

  header {
    height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    > p {
      flex: 1;
      text-align: center;
    }
  }

  html,
  body,
  #app,
  .container {
    height: 100%;
  }

  .container {
    overflow: auto;
  }
  ::before,
  ::after,
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
</style>
