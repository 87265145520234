import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant'
import 'vant/lib/index.css'
import { createPinia } from 'pinia'

const app = createApp(App)
app.use(Vant)

app.use(router).use(createPinia()).mount('#app')
